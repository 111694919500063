import React from "react";
import classes from "./FancyButton.module.css";

const FancyButton = (props) => {
    //to edit colour, find the place in the css where it says 0 164 153
    return (
        <div className = {classes.fancy_button_container}>
        <button 
        onClick = {props.onClick}
        className={`${classes.btn} ${classes['p-4']} ${classes.relative} ${classes['border-0']} ${classes.uppercase} ${classes['text-amber-300']} ${classes.shadow} ${classes['bg-transparent']} ${classes['hover:delay-[.2s]']} ${classes['transition-all']} ${classes['duration-200']} ${classes['hover:text-white']} ${classes['before:absolute']} ${classes['before:left-0']} ${classes['before:bottom-0']} ${classes['before:h-[2px]']} ${classes['before:w-0']} ${classes['before:transition-all']} ${classes['before:duration-200']} ${classes['before:bg-amber-300']} ${classes['before:hover:w-full']} ${classes['after:absolute']} ${classes['after:left-0']} ${classes['after:bottom-0']} ${classes['after:h-0']} ${classes['after:w-full']} ${classes['after:transition-all']} ${classes['after:duration-200']} ${classes['after:bg-amber-300']} ${classes['after:hover:h-full']} ${classes['after:text-white']} ${classes['after:-z-10']} ${classes['after:hover:delay-[0.4s]']}`}>
            
            <span className = {props.content === '+' ? classes.span_plus : classes.span_minus}> {props.content} </span>
        </button>
        </div>
    )
}

export default FancyButton