import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import params from "../../shared/utilities/global_parameters";
import classes from "./TermsModal.module.css";

const Backdrop = () => {
  return <div className={classes.background} />;
};

const ModalOverlay = (props) => {
  const [tcsAgreed, setTcsAgreed] = useState(false);
  const [password, setPassword] = useState('')

  const tcsCheckHandler = () => {
    setTcsAgreed(!tcsAgreed);
  };

  return (
    <div className={classes.modal}>
      <h2>{params.app_name_long} - Please Read</h2>
        <p>
        Welcome to the {params.app_name_long} - please read these terms of service
        before you use it. 
        </p>
        <p>
        The calculator is for guidance and illustration only and the figures you
        get are not a final retirement benefit offer. They are an illustration
        of basic service benefits and are not financial advice. If you need
        financial advice, please contact an independent financial adviser
        – you can find one at {" "}
        <a href="https://www.unbiased.co.uk/ "> unbiased.co.uk </a>
        </p>
      <p>The calculator uses the following assumptions:</p>
      <table>
        <tbody>
          <tr>
            <td>Consumer Price Index</td>
            <td>2% per year</td>
          </tr>
          <tr>
            <td>Earnings inflation</td>
            <td>2% per year</td>
          </tr>

        </tbody>
      </table>
      <p>
        <b>
        We cannot accept any liability for actions you may take based on 
        these illustrations
        </b>
      </p>
      <label className={classes.checkbox_label}>
        <input
          className={classes.checkbox}
          type="checkbox"
          onClick={tcsCheckHandler}
        />
        {"  "}I acknowledge that I have read, agree and understand the terms of
        service
      </label>
      <div className = {classes.password}> 
      <p> password </p>
      <input type = 'text' onChange = {(e) => setPassword(e.target.value)}/>
      </div>
      <br />
      {password === 'GAD001' && 
      <button
        className={`${classes.btn_main} ${
          tcsAgreed ? classes.btn_agreed : classes.btn_reject
        }`}
        onClick={tcsAgreed ? props.onClick : undefined}
      >
        Continue to the {params.app_name_long}
      </button>
    }
    </div>
  );
};

const TermsModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay onClick={props.onClick} />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default TermsModal;
